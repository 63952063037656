/* ------------------------------------------------------------------------------- */
/* --- PROJECT SHOW CSS --- */
/* ------------------------------------------------------------------------------- */
.card {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
}
.card:not(:last-child) {
  margin-bottom: 40px;
}
.card_header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.post_auth_info {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.profile_image img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.post_user_fet button {
  color: #333;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.post_user_fet button:hover {
  text-decoration: underline;
}
.post_user_fet a {
  color: #333;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.post_user_fet a:hover {
  text-decoration: underline;
}
.post-features {
  color: #777;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 3px;
}
.post-features span {
  width: 4px;
  height: 4px;
  background: #ccc;
  display: inline-block;
  border-radius: 50px;
  margin-left: 5px;
}
.post_arrow button {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}
.post_arrow button svg {
  fill: #777777;
}
.post_arrow button:hover svg {
  fill: #333;
}
.card_title {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  word-wrap: break-word;
}
.card_text {
  color: #667085;
  padding-top: 20px;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  margin-bottom: 20px;
  word-wrap: break-word;
}
.card_body .al_project_learn_more {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 6px;
  transition: 0.3s;
  max-width: 110px;
}
.card_body .al_project_learn_more:hover {
  color: #555;
  text-decoration: underline;
}
.project_resourse {
  border-radius: 10px;
  border: 1px solid #eee;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 15px;
  margin: 20px 0;
}
.project_reso_details {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.likded_users {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 5px;
}
.likded_users a:not(:first-child) {
  margin-left: -10px;
}
.likded_users img {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  object-fit: cover;
  border: 2px solid #ffff;
}
.project_reso_details p {
  color: #777;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.project_effective_button {
  color: #777;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border: none;
  background: transparent;
  gap: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50px;
}
.project_effective_button.liked {
  background: #3498db17;
  color: #3498db;
}
.project_effective_button:hover {
  background: #00000018;
  color: #000;
}
.project_effective_button.btnVoteUp.voteStActive {
  color: #2ecc71;
  background: #2ecc7023;
}
.project_effective_button.btnVoteUp.voteStActive svg {
  color: #2ecc71;
  fill: #2ecc71;
}
.project_effective_button.btnVoteDown.voteStActive {
  color: #e74c3c;
  background: #e74d3c1c;
}
.project_effective_button.btnVoteDown.voteStActive svg {
  color: #e74c3c;
  fill: #e74c3c;
}
.project_comment_features {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.post-features a {
  color: #aaa;
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: 0.3s;
}
.post-features p {
  overflow: inherit;
}
.post-features a:hover {
  text-decoration: underline;
  color: #000;
}
.post-features svg {
  font-size: 14px;
}
.project_show_footer {
  /* padding-bottom: 80px; */
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_show_more {
  color: #fff;
  background: #000;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
}
.btn_show_more:hover {
  opacity: 0.7;
}
.al_resFilterBtn {
  display: none;
}
.side_bar_mobile_header {
  display: none;
}

/* ----------------------------------- */
/* RESPONSIVE CSS   */
/* --------------------------------- */
/* For 991px breakpoint and smaller */
@media (max-width: 991px) {
  .project_show_wrapper {
    position: relative;
  }
  .project_show_container {
    flex: 0 0 100%;
    padding: 0;
  }
  .project_side_ber_container {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
    height: 100%;
    padding: 0;
    background: #000000ab;
    display: none;
  }
  .project_side_ber_container.active_side_bar {
    display: block;
  }

  .project_side_bar {
    height: calc(100% - 200px);
    overflow-x: scroll;
    margin-top: 200px;
    padding: 0;
  }
  .project_side_bar .side_bar_inputs_box {
    padding: 0px 30px;
    padding-bottom: 20px;
  }

  .al_resFilterBtn {
    display: flex;
  }
  .sitebaractivebody {
    overflow: hidden;
  }

  /* sidebar header css  */
  .side_bar_mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 30px;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
  }

  .side_bar_head_btn {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    padding: 9px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .full_widht_project_details_area .project_side_ber_container {
    position: inherit;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #fff;
    display: block;
  }
  .full_widht_project_details_area .project_side_bar {
    height: inherit;
    overflow-x: inherit;
    margin-top: 0px;
    padding: 20px;
  }
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  /* .project_short_filter {
    flex-wrap: wrap;
  } */
  .short_filter_button {
    height: 45px;
  }
  .project_show_top_searchbar {
    overflow-x: scroll;
    margin-bottom: 25px;
  }
  .project_show_section .short_filter_button {
    flex: inherit;
  }
  .project_short_filter {
    background: transparent;
    box-shadow: none;
    padding: 5px 10px;
    width: 740px;
    padding-bottom: 0px;
  }
  .card_title {
    font-size: 20px;
    line-height: 28px;
  }
  .card_body a {
    font-size: 15px;
    line-height: 24px;
    display: block;
  }
  .card:not(:last-child) {
    margin-bottom: 30px;
  }
  .project_show_section {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .full_widht_project_details_area .project_show_left_box {
    padding: 15px 0;
  }
  .full_widht_project_details_area .project_side_ber_container {
    padding: 15px 0;
  }
}

/* For 576px breakpoint and smaller */
@media (max-width: 576px) {
  .project_effective_button {
    font-size: 13px;
    gap: 2px;
    padding: 3px 5px;
  }
  .post_user_fet button {
    font-size: 15px;
  }
  .card {
    border-radius: 15px;
    padding: 25px 15px;
  }
  .card:not(:last-child) {
    margin-bottom: 20px;
  }
  .card_body .al_project_learn_more {
    font-size: 15px;
  }
  .card_text {
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .post_auth_info {
    align-items: flex-start;
  }
  .card_title {
    font-size: 18px;
    line-height: 1.5;
  }
  .container {
    padding: 0px 15px;
  }
  .profile-image {
    width: 35px;
    height: 35px;
  }
}
