.dashboard {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
}
.add_project_head {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}
.confiramation_options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
}
.dashboard_image img {
  max-width: 450px;
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
}
/* dashboard_add_project */
.dashboard_add_project {
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.add_project_head {
  border-bottom: 1px solid #eaecf0;
  padding: 20px;
}
.add_project_head h3.title {
  color: #101828;
  font-size: 24px;
  font-weight: 500;
}
.add_project_form {
  padding: 20px;
}
.dashboard_add_project .form_control {
  padding-bottom: 20px;
}
.dasMenuBtn {
  font-size: 20px;
  display: none;
}
.upload_avater_withOld {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.upload_avater_withOld .drag_imge_input {
  margin: 0;
  width: 100%;
}
.show_old_avater {
  max-width: 64px;
  max-height: 64px;
  border-radius: 50px;
  overflow: hidden;
  object-fit: cover;
}

.show_old_avater img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .dashboard {
    margin-top: 0px;
  }
  .dasMenuBtn {
    display: block;
  }
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  .add_project_head h3.title {
    font-size: 20px;
  }
  .dasMenuBtn {
    font-size: 17px;
  }
  .add_project_head {
    gap: 7px;
  }
}

/* For 576px breakpoint and smaller */
@media (max-width: 576px) {
  .two_columns {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .add_project_form .list_input_box,
  .add_project_head .list_input_box {
    flex-direction: column;
  }
  .add_project_form .list_input_pass,
  .add_project_form .list_input,
  .list_input_pass {
    width: 100%;
    gap: 5px;
  }
  .upload_avater_withOld {
    flex-direction: column;
    row-gap: 15px;
  }
  .add_project_form {
    padding: 20px 15px;
  }
}
