* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:after,
*:before {
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  line-height: 2.4rem;
}
body {
  position: relative;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  background: #fff;
  color: #646464;
}
#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
accordion-header h3,
accordion-header span,
svg,
img {
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
p {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  display: inline-block;
}
input {
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
/* CSS */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  border: none;
  outline: none;
  background: transparent;
  font-family: inherit;
  cursor: pointer;
}
input,
textarea {
  font-family: inherit;
}
/* Some Extra for pre style */
.two_columns {
  display: flex;
  justify-content: space-between;
}
.two_columns .form_control {
  flex: 0 0 calc(50% - 15px);
}

.addProjectCtSelect .css-hlgwow {
  flex-basis: calc(75% - 15px) !important;
}
.addProjectCtSelect .css-1jqq78o-placeholder {
  flex-basis: calc(25% - 15px) !important;
}
.addProjectCtSelect .css-t3ipsp-control {
  border-color: inherit !important;
  box-shadow: none;
}
/* Some Extra for pre style */

/* ------------------------------------------------------------------------------- */
/* --- GLOBAL CSS --- */
/* ------------------------------------------------------------------------------- */
.modal-open {
  overflow: hidden;
}
.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
h1 {
  color: #000;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
}
h2 {
  color: #01001a;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
}
h3 {
  font-size: 26.738px;
  font-style: normal;
  font-weight: 700;
  line-height: 35.651px;
}
h4 {
  color: var(--gray-900, #101828);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px;
}
h5 {
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
h6 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
p {
  color: #646464;
}
.section_padding {
  padding: 40px 0;
}
/* st global input css  */
textarea,
input {
  color: #667085;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-300, #d0d5dd);
  background: var(--White, #fff);
  box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05);
  padding: 1rem 1.4rem;
  width: 100%;
  resize: none;
}
textarea:focus,
input:focus:focus-visible {
  /* border: none; */
  box-shadow: none;
  outline: none;
}

select,
option {
  color: #667085;
  font-size: 1.6rem;
  font-weight: 400;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--gray-300, #d0d5dd);
  background: transparent;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05);
  padding: 1rem 1.4rem;
  width: 100%;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
}
/* form_radio */
.form_radio {
  display: block;
  margin-bottom: 10px;
}
/* Customize the radio button when checked */
.radio-control {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
input[type="radio"]:checked + .radio-control {
  border-color: #2d2d2d;
}

input[type="radio"]:checked + .radio-control span {
  background-color: #2d2d2d;
  width: 50%;
  height: 50%;
  display: block;
  border-radius: 50px;
}

/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Style the radio label */
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  user-select: none;
  margin: 0;
}
.custom-radio {
  display: block flex !important;
  align-items: center;
}
.custom-radio:hover {
  color: #000;
}
/* Style the custom radio control */
.radio-control {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
  transition: background-color 0.2s, border-color 0.2s;
  border: 1px solid #878787;
  background: #fff;
}
#signup_references .custom-radio {
  font-size: 14px;
}
/* check box plan css  */
.plan {
  cursor: pointer;
  width: inherit;
  min-width: inherit;
  margin: 5px !important;
  width: 100%;
}
.plan input[type="checkbox"] {
  width: 100%;
  padding: 9px 10px;
  border-radius: 6px;
  border: 1px solid #cbd5e1;
  position: absolute;
  opacity: 0;
  display: none;
}
.plan .plan-content {
  padding: 1rem 1.4rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 0.1rem solid var(--gray-300, #d0d5dd);
  border-radius: 6px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}
.plan-details {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  transition: ease-in 0.3s;
}
.plan-details p {
  color: #667085;
  font-size: 1.6rem;
  font-weight: 400;
}

.plan input[type="radio"]:checked + .plan-content .plan-details p,
.plan input[type="checkbox"]:checked + .plan-content .plan-details p {
  color: #ffff;
}
.plan input[type="checkbox"]:checked + .plan-content,
.plan input[type="radio"]:checked + .plan-content {
  border: 1px solid #cbd5e1;
  background: #cbd5e1;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}
.plan-checked-icon {
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #aaa;

  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-right: 9px;
}
.plan-checked-icon .check_icon {
  display: none;
}
.plan
  input[type="radio"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon,
.plan
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon {
  border: 1px solid #5c5c5c;
}
.plan
  input[type="radio"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon
  .check_icon,
.plan
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon
  .check_icon {
  display: block;
}
.plan.plan-button {
  width: inherit;
  display: block ruby;
  margin: 12px 0 !important;
}

.plan-button-colors input[type="checkbox"]:checked + .plan-content,
.plan-button-colors input[type="radio"]:checked + .plan-content {
  background: #2e2e2e !important;
}

.plan-button-colors input[type="radio"]:checked + .plan-content .plan-details p,
.plan-button-colors
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  p {
  color: #fff !important;
}

.plan-button-colors
  input[type="radio"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon,
.plan-button-colors
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon {
  border: 1px solid #fff;
}

.plan-button-colors
  input[type="radio"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon
  .check_icon,
.plan-button-colors
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  .plan-checked-icon
  .check_icon {
  color: #fff;
}

.plan-checked-icon .check_icon svg {
  display: inherit;
}
.check_icon {
  font-size: 14px;
}

/* input plan css */
.input-plan .plan-details {
  justify-content: flex-start;
}

.input-plan .plan-content {
  border: none;
}

.input-plan.plan input[type="checkbox"]:checked + .plan-content,
.input-plan.plan input[type="radio"]:checked + .plan-content {
  border: 1px solid #cbd5e1;
  background: #cbd5e1;
  transition: ease-in 0.3s;
  background: transparent;
  border: none;
}
.input-plan.plan input[type="radio"]:checked + .plan-content .plan-details p,
.input-plan.plan
  input[type="checkbox"]:checked
  + .plan-content
  .plan-details
  p {
  color: #333;
}
.input-plan .check_icon {
  color: #333;
}
.input-plan {
  margin: 0 !important;
  padding-bottom: 0 !important;
}
.EditUserProfile .al_submit_button {
  text-align: left;
}
.input_hint {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 10px 0;
}
/* ED global input css  */

/* custom golbal css  */
.btn {
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  padding: 1rem 1.6rem;
  cursor: pointer;
  transition: 0.3s;
}

.btn-dark {
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #383838;
  box-shadow: 0px 0px 0px 4px #ededed, 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
}
.btn-dark:hover {
  background: rgba(16, 24, 40, 0.05);
  color: #383838;
}
.btn-light {
  border-radius: 8px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
}
.btn-light:hover {
  color: #fff;
  background-color: #000;
}
.btn-gray {
  border-radius: 8px;
  background: rgba(53, 53, 53, 0.1);
  color: #353535;
}
.btn-gray:hover {
  border-radius: 8px;
  background: #eee;
  color: #000;
}
.btn-over-dark {
  border-radius: 0.8rem;
  border: 0.1rem solid #5a5a5a;
  background: #555;
  box-shadow: 0 0.1rem 0.2rem 0 rgba(16, 24, 40, 0.05);
  color: var(--White, #fff);
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.btn-over-dark:hover {
  background-color: rgba(16, 24, 40, 0.05);
}
.btn-full {
  width: 100%;
}
.no-shadow {
  box-shadow: none;
}
.no-border {
  border: none;
}

.btn-lg {
  padding: 16px 28px;
  font-size: 18px;
}
.btn-sm {
  padding: 6px 16px;
  font-size: 12px;
}
.section_header {
  text-align: center;
  margin-bottom: 40px;
}
.section_header p {
  color: #667085;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-top: 10px;
}
.divider {
  display: inline-block;
  border-radius: 10px;
  background: #eee;
  /* box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10); */
  width: 100%;
  height: 1px;
  margin: 14px 0;
}
.truncate-text {
  max-width: 190px; /* Set the maximum width of the <p> tag */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* For meeting modal */
.meeting_modal .title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
}
.meeting_modal .pragraph {
  padding-bottom: 20px;
}
.meeting_modal_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 25px;
  padding-top: 25px;
}
/* ------------------------------------------------------------------------------- */
/* --- Custom Modal CSS --- */
/* ------------------------------------------------------------------------------- */
/* .custom-modal { */
/* position: fixed;
  left: 0;
  right: 0; */
/* width: 100%;
  top: 0;
  height: 100%; */
/* background: #0606069e;
  z-index: 99; */
/* display: none; */
/* } */
/* .modal-wrapper {
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  margin-top: 10rem;
  background: #fff;
  padding: 20px 16px 16px 16px;
  position: relative;
  border-radius: 12px;
  background: var(--White, #fff);
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  transition: 0.4s;
  text-align: center;
} */
.react-responsive-modal-closeButton {
  position: absolute;
  top: -15px;
  right: -15px;
  border-radius: 32px;
  background: #000;
  box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 20px;
  width: 32px;
  height: 32px;
  z-index: 99;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-responsive-modal-closeButton svg {
  color: #fff;
  fill: #fff;
  font-size: 14px;
  height: 24px;
  width: 24px;
}
.react-responsive-modal-closeButton:hover {
  transform: scale(0.9);
}
.modal_head {
  padding-top: 10px;
}
.modal_head img {
  height: 40px;
  width: 100%;
  object-fit: contain;
}
.modal_title {
  color: var(--gray-900, #101828);
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  padding: 20px 0;
}
.modal_footer {
  margin-top: 20px;
}
.have_account p {
  color: #989898;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}
.have_account p a {
  color: #a4a4a4;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  transition: 0.3s;
}
.have_account p a:hover {
  text-decoration: underline;
}
.have_account a:hover {
  color: #383838;
}
.custom-modal .custom_radio {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 2rem;
}
/* .toggle_top {
  transform: translateY(-300px);
} */
.modal-show {
  display: block;
}
.modal-show .toggle_top {
  transform: translateY(0);
}
.react-responsive-modal-modal {
  overflow: initial !important;
}
/* end global css  */

/* Maker Shihab */
.form_control_skills {
  margin-bottom: 15px;
}
.dashboard_menu_profile_title a {
  display: block;
  line-height: 1.2;
  color: inherit;
}
.dashboard_menu_profile_title a:hover {
  text-decoration: underline;
  color: #000;
}
.keywordText_pragraph,
.keywordText {
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.form_control {
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}

.form_control:not(:last-child) {
  padding-bottom: 20px;
}

.form_control label {
  display: block;
  color: #344054b2;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  padding-bottom: 6px;
}
.form_control input {
  color: #101828;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.form_control input::placeholder {
  color: #667085;
}
.form_control textarea:hover,
.form_control textarea:focus,
.form_control input:hover,
.form_control input:focus {
  border-color: #101828;
}
.form_control label span {
  color: red;
}
.form_control textarea {
  resize: vertical;
  color: #101828;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  width: 100%;
  padding: 8px 15px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.form_control.adProjectFormControl {
  overflow-x: inherit;
  text-overflow: inherit;
  word-wrap: inherit;
}
.form_subtitle {
  padding-bottom: 20px;
  padding-top: 10px;
  font-weight: 500;
  font-size: 24px;
  color: #101828;
}
.list_input_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
}
.list_lebel {
  flex: 0 0 30%;
  margin-top: 5px;
}
.list_input {
  flex: 0 0 calc(70% - 10px);
}
.inputhr {
  margin-bottom: 20px;
  margin-top: -5px;
  border-color: #eaecf0;
  border-width: 1px;
  opacity: 0.3;
}

input:read-only:hover,
input:disabled:hover,
input:read-only,
input:disabled {
  pointer-events: none;
  background-color: #f2f2f2;
  color: #9999997c;
  border: 1px solid #ccc;
}

/* Add Project Page */
/* Maker Shihab */
.extraTitleForBreaker {
  padding-top: 50px;
}
.meeting_modal .modal_body {
  padding: 0 20px;
}
/* For modal */

.react-responsive-modal-modal {
  max-width: 520px;
  width: 100%;
  border-radius: 20px;
  background: #fff;
  padding: 20px 16px 16px 16px;
  border-radius: 12px;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  transition: 0.4s;
  text-align: center;
}
.sign_up_preference .form_radio {
  text-align: left;
  max-width: 160px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.sign_up_preference button {
  margin-top: 25px;
}
.sign_up_preference button:disabled {
  opacity: 0.7;
}
/* For modal */
/* Alifur Rahman*/
/* --- fix some global css  */
.al_submit_button {
  /* text-align: right; */
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.al_submit_button .btn {
  width: 100%;
  max-width: 200px;
}
.al_menu_icon {
  margin-bottom: -3px;
}
.error-msg {
  color: red;
  margin-top: 2px;
  font-size: 14px;
}
.border-warring {
  border-color: red !important;
}
.loading_BG {
  position: relative;
}
.loading_BG::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.318);
  /* Adjust the opacity as needed */
  z-index: 9999;
  /* Add other styles for the overlay */
}
/* ----------------------------------- */
/* RESPONSIVE CSS FOR GLOBAL  */
/* --------------------------------- */
.post_arrow .upvateBtn svg.upvoted {
  fill: #007aff;
}
.post_arrow .downvoteBtn svg.downvoted {
  fill: red;
}
/* Modal Socail */
.socail_modal {
  padding: 10px 20px;
}
.socail_modal h4 {
  font-size: 22px;
  text-align: center;
  font-weight: 600;
  color: #62646a;
}
.socail_modal h5 {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
  color: #62646a;
}
.socail_modal_list {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
  padding: 20px 0;
  padding-top: 30px;
}
.socail_modal_list_single {
  text-align: center;
}
.socail_modal_list_single span {
  color: #62646a;
  font-size: 14px;
  display: block;
  padding-top: 5px;
}
.socail_modal_list_single:hover span {
  text-decoration: underline;
}
.socail_modal_list button {
  height: 50px;
  width: 50px;
  display: flex;
  margin: 0 auto;
  color: #fff;
  justify-content: center;
  align-items: center;
  border: 1px solid #62646a;
  border-radius: 50%;
  font-size: 22px;
}
.socail_modal_body {
  text-align: left;
}
.socail_modal_body p {
  font-size: 16px;
  font-weight: 600;
  color: #101828;
  padding-bottom: 5px;
}
.socail_modal_list_single:nth-child(1) button {
  background: rgb(62, 90, 153);
  border-color: rgb(62, 90, 153);
}
.socail_modal_list_single:nth-child(2) button {
  background: rgb(6, 119, 181);
  border-color: rgb(6, 119, 181);
}
.socail_modal_list_single:nth-child(3) button {
  background: rgb(96, 169, 221);
  border-color: rgb(96, 169, 221);
}
.socail_modal_list_single:nth-child(4) button {
  background: rgb(13, 159, 22);
  border-color: rgb(13, 159, 22);
}
.socail_modal_cp button {
  color: #62646a;
}
.active.socail_modal_cp button,
.active.socail_modal_cp span {
  color: rgb(13, 159, 22);
  border-color: rgb(13, 159, 22);
}
/* For Dashboard Fetch All Project by user */
.dashboard_all_projectBody {
  padding: 20px;
  min-height: 350px;
}
.al_add_comment_box {
  padding-top: 20px;
}
.al_add_comment_box textarea {
  width: 100%;
  resize: vertical;
}
/* For Header */
.delete_modal_body {
  padding: 20px 15px;
}
.delete_modal_title {
  padding-bottom: 10px;
  font-size: 28px;
  font-weight: 600;
}
.modal_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding-top: 30px;
}
.btn-danger {
  background: #e74c3c;
  color: #fff;
  border-radius: 8px;
}
/* For user Details */
.userProfile_title {
  text-transform: capitalize;
}
.main_dashboard_content {
  padding: 30px;
}
.single_confiramation {
  margin-bottom: 30px;
}
.email_confirmation {
  text-align: center;
  max-width: 550px;
  margin: 0 auto;
}
.single_confiramation img {
  width: 120px;
  height: 100px;
  object-fit: contain;
}
.email_confirmation h4 {
  font-size: 28px;
  padding: 15px 0 10px 0;
}
.email_confirmation p {
  padding-bottom: 15px;
}
.email_confirmation p span {
  color: #2ecc71;
  font-weight: 500;
}
.email_confirmation p.info_small {
  color: #222;
  font-size: 14px;
  display: block;
  padding-top: 20px;
}
.email_confirmation p.info_small button {
  color: #2ecc71;
  font-weight: 500;
}
.email_confirmation p.info_small button:hover {
  text-decoration: underline;
}
/* For Password */
.list_input_pass {
  position: relative;
}
.list_input_pass .password-toggle-btn {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  border-radius: 0 8px 8px 0;
  height: 100%;
  color: #fff;
  font-size: 18px;
  width: 44px;
  background: #383838;
}
@keyframes spinning {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg); /* Start rotation from 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate to 360 degrees */
  }
}
.spinning_icon {
  animation: rotate 2s linear infinite;
}
.al_voteCount {
  font-size: 16px;
}
.al_pHeader_buttons_area {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 3px;
}
.project_more_btn {
  padding: 7px !important;
}
/* ToolTip */
/* tooltip.css */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 125%;
  font-size: 14px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
/* ToolTip */
/* Global Header */
.content__header {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.content__header h1 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.content__header p {
  color: #666;
  font-size: 16px;
}
/* Maker */
/* ScrollToTopButton.css */
.scroll-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 25px;
  background-color: #2e2e2e;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  /* display: none; */
  border-radius: 50%;
  font-size: 18px;
  height: 50px;
  width: 50px;
}

.scroll-to-top-button:hover {
  opacity: 0.8;
}
.visibility_modal .modal_body {
  display: inline-block;
  column-count: 2;
  width: 100%;
  padding: 15px 0;
  padding-left: 90px !important;
}
.allProject_data .short_filter_button.al_resFilterBtn {
  display: none !important;
}
/* ----------------------------------- */
/* RESPONSIVE CSS FOR GLOBAL  */
/* --------------------------------- */
@media (max-width: 1280px) {
  .container {
    padding: 0px 20px;
  }
}
/* For 991px breakpoint and smaller */
@media (max-width: 991px) {
  h1 {
    font-size: 46px;
    line-height: 60px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
  h3 {
    font-size: 22.738px;
    line-height: 18.651px;
  }
  .section_padding {
    padding: 30px 0;
    padding-top: 40px;
  }
  .btn-lg {
    padding: 14px 25px;
    font-size: 15px;
  }
  .others_menu {
    padding-top: 15px;
  }
  .extraTitleForBreaker {
    padding-top: 40px;
  }
  .meeting_modal .modal_body {
    padding: 0 10px;
  }
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  h1 {
    font-size: 32px;
    line-height: 45px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h4 {
    font-size: 25px;
    line-height: 35px;
  }
  h5 {
    font-size: 18px;
    line-height: 26px;
  }
}

/* For 576px breakpoint and smaller */
@media (max-width: 576px) {
  body {
    font-size: 14px;
  }
  h1 {
    font-size: 28px;
    line-height: 40px;
  }
  p {
    font-size: 15px;
  }
  h2 {
    font-size: 24px;
    line-height: 36px;
  }
  h3 {
    font-size: 18px;
    line-height: 1.5;
  }
  h5 {
    font-size: 16px;
    line-height: 28px;
  }
  .section_header {
    margin-bottom: 25px;
  }
  .section_header p {
    font-size: 16px;
    line-height: 24px;
  }
  .btn-lg {
    padding: 12px 20px;
    font-size: 14px;
  }
  .post-features p {
    font-size: 13px;
  }
  .inputhr {
    margin-bottom: 10px;
    margin-top: 0px;
  }
  .form_control:not(:last-child) {
    padding-bottom: 15px;
  }
  .form_control label {
    font-size: 14px;
    /* padding-bottom: 0px; */
  }
  .editUpdateSection .form_control label {
    padding-bottom: 0px;
  }
  .editUpdateSection .list_input_box {
    gap: 5px;
  }
  .section_padding {
    padding-bottom: 20px;
  }
  .socail_modal {
    padding: 10px;
  }
  .react-responsive-modal-modal {
    max-width: 450px;
    padding: 20px 15px 15px 16px;
  }
  .socail_modal_list {
    column-gap: 10px;
    padding: 20px 0;
  }
  .extraTitleForBreaker {
    padding-top: 20px;
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .react-responsive-modal-modal {
    max-width: 400px;
    padding: 20px 15px 15px 16px;
  }
  .socail_modal_list button {
    height: 40px;
    width: 40px;
    font-size: 20px;
  }
  .socail_modal_list_single span {
    font-size: 12px;
  }
  .react-responsive-modal-closeButton svg {
    font-size: 14px;
    height: 20px;
    width: 20px;
  }
  .react-responsive-modal-closeButton {
    width: 25px;
    height: 25px;
  }
  .extraTitleForBreaker {
    padding-top: 15px;
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .react-responsive-modal-modal {
    max-width: 350px;
    padding: 20px 15px 15px 16px;
  }
}
