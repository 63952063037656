/* ------------------------------------------------------------------------------- */
/* --- FOOTER CSS --- */
/* ------------------------------------------------------------------------------- */
footer {
  background: #000;
  padding: 6.4rem 0 4.8rem 0;
}
.footer_top_area {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.footer_top_left {
  flex: 0 0 70%;
}
.footer_top_right {
  flex: 0 0 30%;
}
.footer_top_left ul {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 3.2rem;
  margin-top: 3.2rem;
}
footer ul li a {
  color: #656565;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
  transition: 0.3s;
  display: block;
}
footer ul li a:hover {
  color: #fff;
}
.footer_title {
  color: var(--White, #fff);
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 1rem;
}
.subcribe_form {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 10px;
}
.footer_bottom_area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 5rem;
  padding-top: 2rem;
  border-top: 1px solid #363636;
}
.footer_bottom_area ul {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 1rem;
  column-gap: 15px;
}
.footer_bottom_area ul li a {
  color: #aaa;
}
.copyright_text {
  color: #aaa;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
}
.copyright_text a {
  color: #aaa;
}
.copyright_text a:hover {
  color: #aaa;
  text-decoration: underline;
}
.auto-fit {
  height: calc(100vh - 40.8rem);
}

/* ----------------------------------- */
/* RESPONSIVE CSS   */
/* --------------------------------- */
/* For 991px breakpoint and smaller */
@media (max-width: 991px) {
  .footer_top_left {
    flex: 0 0 40%;
  }
  .footer_top_left ul {
    display: block;
    justify-content: unset;
    align-content: unset;
    align-items: unset;
    gap: unset;
    margin-top: 3.2rem;
    column-count: 2;
  }
  .footer_top_right {
    flex: 0 0 50%;
  }
  .footer_top_left ul li {
    margin-bottom: 10px;
  }
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  .footer_top_area {
    flex-direction: column;
  }
  .footer_top_left,
  .footer_top_right {
    flex: 0 0 100%;
    width: 100%;
  }
  .footer_top_left {
    flex: 0 0 40%;
    margin-bottom: 15px;
  }
}

/* For 576px breakpoint and smaller */
@media (max-width: 576px) {
  .footer_bottom_area {
    flex-direction: column-reverse;
  }
  .footer_bottom_area ul {
    padding-bottom: 5px;
  }
  footer ul li a {
    font-size: 1.5rem;
  }
}
