/* for post comment box  */
/* pin box  */
.project_outer_box {
  flex: 0 0 66.66%;
  width: 100%;
  max-width: 100%;
}
.comment_pin_box {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
}
.pin_icon {
  color: #000000;
  font-size: 20px;
}
.user_thum_style {
  /* width: 100%; */
  /* max-width: 44px; */
  /* height: 100%; */
  /* max-height: 44px; */
  /* border-radius: 50px; */
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50%;
}
/* comment card  */
.comment_card_head {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.commenter_info {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 18px;
}
.comment_time {
  color: #667085;
  font-size: 14.413px;
  font-weight: 400;
  line-height: 21.619px;
}
.comment_date {
  color: #949297;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.comment_card_body {
  margin: 20px 0;
}
.comment_card_body p {
  margin-bottom: 10px;
}
.comment_card_body p:last-child {
  margin-bottom: 0px;
}

.devide_buttons_wraper {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.comment_box_buttons {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  gap: 20px;
}
.post_arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 6px;
}
.post_arrow button {
  font-size: 21px;
}

.comment_vote_count {
  color: #777;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}

.project_effective_button svg {
  font-size: 18px;
}

.project_effective_button.replay_btn span {
  margin-bottom: 1px;
}
.box_open_close_icon {
  width: 20px;
  height: 20px;
  border: 2px solid;
  border-radius: 50px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
/* reply card css  */
.comment_card.replay_card {
  padding: 24px;
  border-left: 1px solid #efefef;
  padding-top: 15px;
  margin-left: 9px;
  margin-top: -5px;
}
.comment_card.replay_card .comment_card_footer,
.comment_card.replay_card .comment_card_body {
  padding-left: 63px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
/* add comment form  */
.add_comment_f_btns {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  gap: 21px;
  margin-top: 10px;
}
.add_comment_f_btns button {
  width: 100%;
  max-width: 90px;
}
.comment_card_footer .al_add_comment_box {
  margin-left: 9px;
  border-left: 1px solid #efefef;
  padding: 24px;
}
.replay_card .al_add_comment_box {
  margin-left: 0;
  border-left: none;
  padding: 24px;
}
.details_block.commnet_add_box {
  width: 100%;
  /* max-width: 680px; */
}
.comment_card {
  margin-bottom: 50px;
}
.comment_card:last-child {
  margin-bottom: 0px;
}

.show_more_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 10px;
  color: #000000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: 0.3s;
}
.show_more_button:hover {
  color: #777;
}
.comment_card.replay_card {
  margin-bottom: 0;
}
@media screen and (max-width: 576px) {
  .comment_card {
    margin-bottom: 35px;
  }
  .comment_card_body {
    margin-top: 10px;
  }
}
