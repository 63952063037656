/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

/* ------------------------------------------------------------------------------- */
/* --- AUTH FORM CSS --- */
/* ------------------------------------------------------------------------------- */
.auth_area {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
}
.auth_area p {
  color: #667085;
  font-size: 14px;
}
.auth_box {
  padding: 10px 0;
}
.auth_box label span {
  color: red;
}
.padding_top-30 {
  padding-top: 30px;
}
.auth_area label {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: block;
  padding-bottom: 6px;
}
.auth_submit {
  margin-top: 24px;
  margin-bottom: 32px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.full_widht_auth_section {
  padding-top: 20px;
  padding-bottom: 100px;
  position: relative;
}
.have_auth_msg {
  text-align: center;
  font-size: 14px;
}
.have_auth_msg a,
.have_auth_msg button {
  color: #1b1b1b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
}
.have_auth_msg a:hover,
.have_auth_msg button:hover {
  color: #667085;
  text-decoration: underline;
}
/* auth other menu  */
.others_menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.others_menu a {
  color: #101828;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: inline-block;
  padding: 6px 8px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.others_menu a:hover {
  color: #2d2d2d;
}
.others_menu a:hover {
  color: #667085;
}
.drag_imge_input {
  border-radius: 8px;
  border: 1px solid #eaecf0;
  background: #fff;
  text-align: center;
  padding: 16px 24px !important;
  margin: 30px 0;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: flex !important;
}
.drag_imge_input input {
  display: none;
}

.drag_imge_input img {
  margin-bottom: 12px;
}
.dargedImage {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 5px;
}

.al_icon_upadiong {
  border: 15px solid #f9fafb;
  background: #f2f4f7;
  font-size: 30px;
  padding: 10px;
  border-radius: 50px;
  margin-bottom: 12px;
}
.dragging {
  border-color: #007bff;
  background-color: #f0f0f0;
  border-style: dashed;
  border-width: 2px;
}
.drag_imge_input p {
  color: var(--gray-500, #667085);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.drag_imge_input p span {
  color: #232323;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.auth_box .plan-details {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.auth_box .plan-details p {
  color: #667085;
  font-size: 14px;
}
.auth_box .plan .plan-content {
  border: none;
  padding: 0;
}
.auth_box .plan input[type="checkbox"]:checked + .plan-content,
.auth_box .plan input[type="radio"]:checked + .plan-content {
  border: none;
  background: unset;
}
.auth_box .plan input[type="radio"]:checked + .plan-content .plan-details p,
.auth_box .plan input[type="checkbox"]:checked + .plan-content .plan-details p {
  color: #344054;
}
.auth_box .plan {
  margin: 0 !important;
}
.auth_box .plan-content {
  margin-top: 10px;
}
.info_box-msg {
  background: #fff3cd;
  color: #856404;
  padding: 10px 12px;
  border: 1px solid #fbe193;
  border-radius: 0.25rem;
  margin-bottom: 20px;
}

/* ----------------------------------- */
/* RESPONSIVE CSS   */
/* --------------------------------- */
/* For 991px breakpoint and smaller */
@media (max-width: 991px) {
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  .padding_top-30 {
    padding-top: 18px;
  }
  .full_widht_auth_section {
    padding-bottom: 50px;
  }
}

/* For 576px breakpoint and smaller */
/* @media (max-width: 576px) {
} */
