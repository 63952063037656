/* ------------------------------------------------------------------------------- */
/* --- HEADER CSS --- */
/* ------------------------------------------------------------------------------- */
header {
  padding: 28px 0px;
  position: relative;
  z-index: 999;
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo a {
  height: 35px;
  display: block;
}
.logo a img {
  height: 100%;
  width: auto;
  object-fit: contain;
}
/* .search_category::after {
    content: "\f078";
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    top: 50%;
    right: 10px;
    transform: translateY(-42%);
    font-size: 15px;
    color: #595959;
    pointer-events: none;
  } */

/* Style the custom dropdown container */
.custom-dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-button {
  background-color: transparent;
  color: rgba(135, 138, 140, 1);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
}
.dropdown-button i {
  margin-left: 5px;
}
.dropdown-list {
  list-style: none;
  padding: 0;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: none;
  min-width: 200px;
  transform: translateY(10px);
}
.dropdown-list li {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-list li i {
  margin-right: 10px;
}
.custom-dropdown:hover .dropdown-list {
  display: block;
}

.header_buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 15px;
}
.res-search-btn {
  display: none;
}

/* user dropdown  */
.user-dropdown {
  position: relative;
  padding-left: 20px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  right: 0;
  margin-top: 16px;
}

.dropdown-content button,
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 10px;
  width: 100%;
}

.dropdown-content button:hover,
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.user_dropdown_menu_itme {
  position: relative;
  z-index: 4;
  background: #f9f9f9;
}

.idicator_icondiv {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -10px;
  right: 7px;
  background: #f9f9f9;
  transform: rotate(45deg);
  z-index: 1;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
}
/* For notification button */
.btn_notification {
  font-size: 24px;
  color: rgba(135, 138, 140, 1);
  position: relative;
}
.btn_notification span {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(150, 3, 3);
  color: #fff;
  font-weight: 300;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  position: absolute;
  top: 10px;
  right: 15px;
}
.notification_area {
  position: relative;
  z-index: 1;
}
.notification_menu {
  position: absolute;
  top: 55px;
  right: 0;
  width: 420px;
  background: #f9f9f9;
  border-radius: 8px;
  z-index: 9999;
}
.notification_content {
  position: relative;
  background: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding-bottom: 15px;
  border-radius: 12px;
}
.notification_content .idicator_icondiv {
  background: #fff;
  z-index: -1;
  top: -12px;
  right: 15px;
}
.notification_body {
  max-height: 280px;
  overflow-y: auto;
  height: 100%;
}
.notification_head {
  padding: 15px;
  display: flex;
  align-items: center;
}
.notification_head svg {
  line-height: 1.2;
  transform: translateY(2px);
  color: #404145;
}
.notification_head h3 {
  font-size: 18px;
  line-height: 1.2;
  color: #404145;
}
.notification_item {
  display: grid;
  grid-template-columns: 50px auto;
  column-gap: 10px;
  border-top: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  background: #f9f9f9;
  color: #404145;
}
.notification_item:hover {
  /* opacity: 0.8; */
  background: #e6e6e693;
}
.notification_item_icon {
  height: 50px;
  width: 50px;
}
.notification_item_icon img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.notification_item_text p {
  font-size: 14px;
}
.notification_dateTime p {
  color: #949494;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 500;
  padding-top: 5px;
}
/* ----------------------------------- */
/* RESPONSIVE CSS FOR HEADER  */
/* --------------------------------- */
/* For 991px breakpoint and smaller */
@media (max-width: 1199px) {
  .header_buttons .btn-dark {
    display: none;
  }
}
@media (max-width: 991px) {
  header {
    padding: 20px 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  }
  .header_wrapper {
    position: relative;
  }
  .header_search {
    position: absolute !important;
    width: 100%;
    left: 0;
    bottom: -63px;
    right: 0;
    margin: 0 auto;
    max-width: 100%;
    display: none;
    /* transform: translateY(-100%); */
    transition: transform 0.3s ease;
  }
  .header_buttons .res-search-btn,
  .header_buttons .btn_notification {
    padding: 10px 8px;
  }
  .btn_notification span {
    right: 8px;
  }
  .header_search.active_header_search {
    display: block;
    transform: translateY(0);
  }

  .header_buttons {
    gap: 5px;
  }
  .res-search-btn {
    font-size: 24px;
    display: flex;
  }
}

/* For 767px breakpoint and smaller */
@media (max-width: 767px) {
  .notification_menu {
    right: -80px;
    width: 360px;
  }
  .notification_content .idicator_icondiv {
    right: 88px;
  }
}

/* For 576px breakpoint and smaller */
@media (max-width: 576px) {
  .user-dropdown {
    padding-left: 5px;
  }
  .dropdown-content button,
  .dropdown-content a {
    padding: 10px 15px;
  }
}
